<script>
import {countDays, getDaysTitle, getDisplayDate} from "@/services/helpers"
import {dictVacationTypes} from "@/services/mappers"

export default {
	name: "ListVacations",
	props: {
		vacations: {
			type: Array,
			default: () => [],
		},
		holidays: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		dictVacationTypes: () => dictVacationTypes,
	},
	methods: {
		countDays,
		getDaysTitle,
		getDisplayDate,
	}
}
</script>

<template>
	<v-list>
		<v-list-item
			v-for="({
				start_date: startDate,
				end_date: endDate,
				count = countDays(startDate, endDate, holidays),
				vacation_type: vacationType
			}, vIndex) in vacations"
			:key="vIndex"
		>
			<v-list-item-avatar class="mr-1">
				<v-avatar color="secondary">
					<span style="color: white">
						{{ vIndex + 1 }}
					</span>
				</v-avatar>
			</v-list-item-avatar>
			<v-list-item-content>
				<v-list-item-title class="pl-1">
					{{ getDisplayDate(startDate, endDate) }}
					<v-chip
						small
						label
						outlined
					>
						{{ count }} {{ getDaysTitle(count) }}
					</v-chip>
				</v-list-item-title>
				<v-list-item-subtitle
					v-if="vacationType === dictVacationTypes.ADDITIONAL"
					class="font-italic pl-1"
				>
					Дополнительный отпуск
				</v-list-item-subtitle>
			</v-list-item-content>
		</v-list-item>
	</v-list>
</template>