<script>
import {dictStatuses, getStatusColor, getStatusIcon, getStatusText} from "@/services/mappers"

export default {
	name: "LabelStatus",
	props: {
		status: {
			type: String,
			default: "",
		},
		small: {
			type: Boolean,
		},
	},
	data() {
		return {
			dictStatuses,
		}
	},
	computed: {
		statusInternal() {
			return this.status || dictStatuses.NEW
		},
		statusText() {
			return getStatusText(this.statusInternal)
		},
		statusColor() {
			return getStatusColor(this.statusInternal)
		},
		statusIcon() {
			return getStatusIcon(this.statusInternal)
		},
	}
}
</script>

<template>
	<v-chip
		:color="statusColor"
		:text-color="statusColor"
		:small="small"
		outlined
		v-on="$listeners"
	>
		<v-icon
			small
			:color="statusColor"
			class="mr-1"
		>
			{{ statusIcon }}
		</v-icon>
		{{ statusText }}
	</v-chip>
</template>