<script>
import {dictStatuses, getStatusIcon} from "@/services/mappers"
import CardUser from "@/components/Card/CardUser.vue"
import LabelStatus from "@/components/LabelStatus.vue"
import {getDateTime} from "@/services/helpers"

export default {
	name: "DialogHistory",
	components: {LabelStatus, CardUser},
	props: {
		application: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			dictStatuses,
			dialog: false,
		}
	},
	computed: {
		historyForDisplay() {
			return this.application?.histories
		},
	},
	methods: {
		getDateTime,
		getStatusIcon,
	}
}
</script>

<template>
	<v-dialog
		v-model="dialog"
		max-width="500px"
	>
		<template #activator="{on, attrs}">
			<slot
				name="activator"
				:on="on"
				:attrs="attrs"
			/>
		</template>
		<v-card max-width="500px">
			<v-card-title>
				История изменения статуса заявки
			</v-card-title>
			<v-card-text>
				<v-timeline
					dense
					align-top
					:class="'final-application-status'"
				>
					<v-timeline-item
						v-for="(comment, index) in historyForDisplay"
						:key="`timeline-item-${index}`"
						color="#e0e0e0"
						fill-dot
					>
						<template #icon>
							<v-icon>{{ getStatusIcon(comment.new_status) }}</v-icon>
						</template>
						<v-card flat>
							<v-card-title class="status-title">
								<LabelStatus
									:status="comment.new_status"
									style="margin-left: -1px"
									small
								/>
							</v-card-title>
							<v-card-subtitle>
								<p
									style="text-align:left"
									class="mb-0"
								>
									<span
										v-if="false"
										style="float:right;text-align:right"
									>
										{{ getDateTime(comment.timestamp) }}
										<br>
										<CardUser
											v-if="comment.created_by"
											:user="comment.created_by"
											x-small
										/>
									</span>
								</p>
								<p
									v-if="true"
									class="mb-0 pt-2"
									style="text-align:left"
								>
									{{ getDateTime(comment.timestamp) }}
									<br>
									<CardUser
										v-if="comment.created_by"
										:user="comment.created_by"
										x-small
									/>
								</p>
							</v-card-subtitle>
							<v-card-text>
								<span v-if="comment.comment">{{ comment.comment }}</span>
								<span
									v-else
									class="font-italic"
								>
									Нет комментария
								</span>
							</v-card-text>
						</v-card>
					</v-timeline-item>
				</v-timeline>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<style>
.status-title {
				padding-top: 7px !important;
}
.v-timeline {
				border-bottom: rgba(0, 0, 0, 0.12) dashed 2px;
}
.final-application-status {
				border-bottom: rgba(0, 0, 0, 0.12) solid 2px;
}
.v-timeline-item__dot {
				box-shadow: none !important;
}
.float-to-left {
				float: left;
				margin-bottom: 0 !important;
}
.float-to-right {
				float: right;
				margin-bottom: 0 !important;
}
</style>