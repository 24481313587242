import {dictRoles, dictStatuses} from "./mappers"


export function getDate(date) {
	if (!date) return null
	try {
		const dateObj = new Date(date)
		return dateObj.toLocaleString("ru-RU", {
			dateStyle: "medium",
		})
	} catch (e) {
		return null
	}
}

export function getDateTime(timestamp) {
	if (!timestamp) return null
	try {
		const date = new Date(timestamp * 1000)
		return date.toLocaleString("ru-RU", {
			dateStyle: "medium",
			timeStyle: "short"
		})
	} catch (e) {
		return null
	}
}

export function countDays(start, end, holidays = []) {
	if (!start || !end) return 0
	const oneDay = 24 * 60 * 60 * 1000
	const firstDate = new Date(start)
	const secondDate = new Date(end)
	let count = Math.round(Math.abs((firstDate - secondDate) / oneDay)) + 1
	for (let i = 0; i < holidays.length; i++) {
		const holiday = new Date(holidays[i])
		if (holiday >= firstDate && holiday <= secondDate) count--
	}
	return count
}

export function countDaysInVacations(vacations, holidays = []) {
	let count = 0
	for (let i = 0; i < vacations.length; i++) {
		const { start_date, end_date } = vacations[i]
		count += countDays(start_date, end_date, holidays)
	}
	return count
}

export function getDaysTitle(count) {
	count = count % 100
	if (count >= 5 && count <= 20) {
		return "дней"
	}
	count = count % 10
	if (count === 1) {
		return "день"
	}
	if (count >= 2 && count <= 4) {
		return "дня"
	}
	return "дней"
}

export function toLocaleDateString(date) {
	if (!date) return null
	const options = { month: "short", day: "numeric" }
	return new Date(date).toLocaleDateString("ru-RU", options)
}

export function getDisplayDate(start, end) {
	const s = toLocaleDateString(start)
	const e = toLocaleDateString(end)
	if (s && e) {
		return `с ${s} по ${e}`
	}
	if (s && !e) {
		return `c ${s}`
	}
	return ""
}

export function getEarliestDate(vacations) {
	if (vacations.length > 0) {
		const dates = vacations.map(({ start_date }) => new Date(start_date))
		return new Date(Math.min(...dates)).toISOString().slice(0, 10)
	}
}

export function extendVacationRanges(vacations) {
	const arr = []
	vacations.forEach(({start_date, end_date}) => {
		const startDate = new Date(start_date)
		const endDate = new Date(end_date)
		const diffTime = Math.abs(endDate - startDate)
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
		for (let i = 0; i <= diffDays; i++) {
			const date = new Date(startDate)
			date.setDate(date.getDate() + i)
			arr.push(date.toISOString().slice(0, 10))
		}
	})
	return arr
}

export function sortAplications(application, role) {
	let status

	switch (role) {
		case dictRoles.TIMEKEEPER:
			status = {
				[dictStatuses.RESCHEDULED]: 1, // download
				[dictStatuses.REJECTED_RESCHEDULED]: 2, // reschedule again
				[dictStatuses.REJECTED]: 3, // edit
				[dictStatuses.ACCEPTED]: 4, // reschedule
				[dictStatuses.NEW]: 5, // no action
			}
			break
		case dictRoles.MANAGER:
			status = {
				[dictStatuses.NEW]: 1, // approve or reject
				[dictStatuses.RESCHEDULED]: 2, // reject
				[dictStatuses.REJECTED_RESCHEDULED]: 3, // no action
				[dictStatuses.REJECTED]: 4, // no action
				[dictStatuses.ACCEPTED]: 5, // no action
			}
			break
		default:
			status = {
				[dictStatuses.NEW]: 1,
				[dictStatuses.REJECTED]: 2,
				[dictStatuses.RESCHEDULED]: 3,
				[dictStatuses.NEW]: 4,
				[dictStatuses.ACCEPTED]: 5
			}
	}
	return application.sort((a, b) => {
		return status[a.status] - status[b.status]
	});
}