<script>

export default {
	name: "DialogWrapper",
	props: {
		title: {
			type: String,
			required: true,
		},
		subtitle: {
			type: String,
			default: "",
		},
		preopen: {
			type: Boolean,
			default: false,
		}
	},
	data() {
		return {
			dialog: this.preopen || false,
			loading: false,
		}
	},
	methods: {
		onCloseDialog() {
			this.dialog = false
		},
	}
}
</script>

<template>
	<v-dialog
		v-model="dialog"
		max-width="500px"
	>
		<template #activator="{on, attrs}">
			<slot
				name="activator"
				:on="on"
				:attrs="attrs"
			/>
		</template>
		<v-card>
			<v-card-title>
				{{ title }}
			</v-card-title>
			<v-card-subtitle>
				{{ subtitle }}
			</v-card-subtitle>
			<v-card-text>
				<slot
					name="text"
				/>
			</v-card-text>
			<v-card-actions>
				<v-row class="ma-0">
					<v-col
						cols="12"
						sm="6"
						order="2"
					>
						<v-btn
							rounded
							block
							depressed
							@click="onCloseDialog"
						>
							Закрыть
						</v-btn>
					</v-col>
					<v-col
						cols="12"
						sm="6"
					>
						<slot
							name="btn"
						/>
					</v-col>
				</v-row>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>